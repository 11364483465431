import { Avatar, Box, Card, Container, Grid, styled, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppWidgetSummary } from '../sections/@dashboard/app';

const OrderDetails = () => {
  const params = useParams();
  const [order, setOrder] = useState({});
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_LINK}/admin_order_details/${params.paymentId}`)
      .then((res) => {
        setOrder(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, [params]);
  const StyledIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(6),
    height: theme.spacing(6),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  }));
  const color = 'error';
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(10),
      }}
    >
      <Card
        sx={{
          width: (theme) => theme.spacing(100),
          py: 5,
          boxShadow: 0,
          textAlign: 'center',
          color: (theme) => theme.palette[color].darker,
          bgcolor: (theme) => theme.palette[color].lighter,
          display: { md: 'flex' },
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Box sx={{ padding: 1 }}>
          <StyledIcon
            sx={{
              color: (theme) => theme.palette[color].dark,
            }}
          >
            <Avatar src="/fds" width={30} height={30} />
          </StyledIcon>
          <Typography variant="h5">{order?.customer?.name}</Typography>

          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            {order?.customer?.email}
          </Typography>
        </Box>

        <Box sx={{ borderLeft: 1, borderColor: 'gray' }} />
        <Box sx={{ borderLeft: 1, borderColor: 'gray', padding: 1, marginTop: { sm: 5, md: 0 } }}>
          <Typography variant="h5">Customer Address</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 1 }}>
            {order?.customer?.address?.line1}
          </Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            {order?.customer?.address?.city}
          </Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            {order?.customer?.address?.country}
          </Typography>
        </Box>
      </Card>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: 'center',
        }}
      >
        {order?.cart?.map((item) => (
          <Grid key={item._id} item xs={12} sm={8} md={5}>
            <AppWidgetSummary
              title={item.serviceTitle}
              link={item.link}
              icon={item.mainService.serviceImage}
              color={item.price > 100 ? 'info' : 'primary'}
              price={item.price}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OrderDetails;
